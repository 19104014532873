<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-12">
                <div class="darmin card header-card theme-form">
                    <div class="row justify-content-end">
                        <div class="col-md-8 d-flex align-items-center justify-content-end">
                            Especialista:

                            <Autocomplete
                                class="ms-2"
                                :debounce="1200"
                                @onInput="getEspecialistas"
                                :items="especialistas"
                                :displayItem="
                                    (item) => (typeof item === 'string' ? item : item.fullName)
                                "
                                placeholder="Buscar un Especialista"
                                @onSelect="onSelectEspecialista"
                                ref="especialistaAutocomplete"
                            ></Autocomplete>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="darmin card">
                    <div class="card-body">
                        <div>
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a
                                        role="button"
                                        class="nav-link"
                                        :class="{ active: tabSelected === 'programadas' }"
                                        aria-current="page"
                                        @click="tabSelected = 'programadas'"
                                        >Programadas</a
                                    >
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a
                                        role="button"
                                        class="nav-link"
                                        :class="{ active: tabSelected === 'enespera' }"
                                        @click="tabSelected = 'enespera'"
                                        >En Espera</a
                                    >
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a
                                        role="button"
                                        class="nav-link"
                                        :class="{ active: tabSelected === 'finalizadas' }"
                                        @click="tabSelected = 'finalizadas'"
                                        >Finalizadas</a
                                    >
                                </li>
                            </ul>
                            <div class="tab-content">
                                <!--Programadas-->
                                <div
                                    class="tab-pane"
                                    :class="{ 'show active': tabSelected === 'programadas' }"
                                >
                                    <div class="table-responsive mb-3">
                                        <table class="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th width="15%">Fecha</th>
                                                    <th width="65%">Paciente</th>
                                                    <th width="12%">Procedimiento</th>
                                                    <th width="8%"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="cita in programed" :key="cita.id">
                                                    <td>{{ normarlizeDate(cita.timeStart) }}</td>
                                                    <td>{{ cita.paciente.fullName }}</td>
                                                    <td>{{ procedimiento(cita) }}</td>
                                                    <td>
                                                        <div
                                                            class="d-flex justify-content-between align-items-center"
                                                        >
                                                            <div
                                                                class="event__view_btn text-danger ms-2 d-flex align-items-center"
                                                                @click.stop="
                                                                    handleCancelEventClick(cita)
                                                                "
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Default tooltip"
                                                                role="button"
                                                            >
                                                                <i class="fas fa-lg fa-times"></i>
                                                            </div>
                                                            <div
                                                                class="event__view_btn text-primary ms-2 d-flex align-items-center"
                                                                @click.stop="
                                                                    handleOpenPacienteClick(cita)
                                                                "
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Default tooltip"
                                                                role="button"
                                                            >
                                                                <i class="fas fa-lg fa-eye"></i>
                                                            </div>
                                                            <div
                                                                class="event__view_btn text-success ms-2 d-flex align-items-center"
                                                                @click.stop="
                                                                    handleonWaitingClick(cita)
                                                                "
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Default tooltip"
                                                                role="button"
                                                            >
                                                                <i
                                                                    class="far fa-arrow-alt-from-left fa-lg"
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div
                                        class="d-flex flex-column align-items-end flex-md-row justify-content-end"
                                        style="font-size: 0.7rem"
                                    >
                                        <div>
                                            <i class="fas fa-lg fa-times text-danger"></i> Cancelar
                                            cita
                                        </div>
                                        <div class="ms-2">
                                            <i class="fas fa-lg fa-eye text-primary"></i> Ver
                                            Paciente
                                        </div>
                                        <div class="ms-2">
                                            <i
                                                class="far fa-arrow-alt-from-left fa-lg text-success"
                                            ></i>
                                            Anunciar paciente
                                        </div>
                                    </div>
                                </div>
                                <!--/Programadas-->
                                <!--EsEspera-->
                                <div
                                    class="tab-pane"
                                    :class="{ 'show active': tabSelected === 'enespera' }"
                                >
                                    <div class="table-responsive mb-3">
                                        <table class="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th width="15%">Fecha</th>
                                                    <th width="65%">Paciente</th>
                                                    <th width="12%">Procedimiento</th>
                                                    <th width="8%"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="cita in wating" :key="cita.id">
                                                    <td>{{ normarlizeDate(cita.timeStart) }}</td>
                                                    <td>{{ cita.paciente.fullName }}</td>
                                                    <td>{{ procedimiento(cita) }}</td>
                                                    <td>
                                                        <div class="d-flex justify-content-between">
                                                            <div
                                                                class="event__view_btn text-danger ms-2 d-flex align-items-center"
                                                                @click.stop="
                                                                    handleCancelEventClick(cita)
                                                                "
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Default tooltip"
                                                                role="button"
                                                            >
                                                                <i class="fas fa-lg fa-times"></i>
                                                            </div>
                                                            <div
                                                                class="event__view_btn text-primary ms-2 d-flex align-items-center"
                                                                @click.stop="
                                                                    handleOpenPacienteClick(cita)
                                                                "
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Default tooltip"
                                                                role="button"
                                                            >
                                                                <i class="fas fa-lg fa-eye"></i>
                                                            </div>
                                                            <div
                                                                class="event__view_btn text-secondary ms-2 d-flex align-items-center"
                                                                @click.stop="
                                                                    handleNewConsultaClick(cita)
                                                                "
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Default tooltip"
                                                                role="button"
                                                            >
                                                                <i
                                                                    class="fas fa-lg fa-file-alt"
                                                                ></i>
                                                            </div>
                                                            <div
                                                                class="text-success ms-2 d-flex align-items-center"
                                                                @click.stop="
                                                                    handleFinishClick(cita)
                                                                "
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Default tooltip"
                                                                role="button"
                                                            >
                                                                <i
                                                                    class="far fa-arrow-alt-from-left fa-lg"
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div
                                        class="d-flex flex-column align-items-end flex-md-row justify-content-end"
                                        style="font-size: 0.7rem"
                                    >
                                        <div>
                                            <i class="fas fa-lg fa-times text-danger"></i> Cancelar
                                            cita
                                        </div>
                                        <div class="ms-2">
                                            <i class="fas fa-lg fa-eye text-primary"></i> Ver
                                            Paciente
                                        </div>
                                        <div class="ms-2">
                                            <i class="fas fa-lg fa-file-alt text-secondary"></i>
                                            Crear consulta
                                        </div>
                                        <div class="ms-2">
                                            <i
                                                class="far fa-arrow-alt-from-left fa-lg text-success"
                                            ></i>
                                            Anunciar paciente
                                        </div>
                                    </div>
                                </div>
                                <!--/EsEspera-->
                                <!--Finalizada-->
                                <div
                                    class="tab-pane"
                                    :class="{ 'show active': tabSelected === 'finalizadas' }"
                                >
                                    <div class="table-responsive mb-3">
                                        <table class="table align-middle">
                                            <thead>
                                                <tr>
                                                    <th width="15%">Hora</th>
                                                    <th width="65%">Paciente</th>
                                                    <th width="12%">Procedimiento</th>
                                                    <th width="8%"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="cita in finished" :key="cita.id">
                                                    <td>{{ normarlizeDate(cita.timeStart) }}</td>
                                                    <td>{{ cita.paciente.fullName }}</td>
                                                    <td>{{ procedimiento(cita) }}</td>
                                                    <td>
                                                        <div class="d-flex justify-content-between">
                                                            <div
                                                                class="event__view_btn text-primary ms-2 d-flex align-items-center"
                                                                @click.stop="
                                                                    handleOpenPacienteClick(cita)
                                                                "
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title="Default tooltip"
                                                                role="button"
                                                            >
                                                                <i class="fas fa-lg fa-eye"></i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div
                                        class="d-flex flex-column align-items-end flex-md-row justify-content-end"
                                        style="font-size: 0.7rem"
                                    >
                                        <div class="ms-2">
                                            <i class="fas fa-lg fa-eye text-primary"></i> Ver
                                            Paciente
                                        </div>
                                    </div>
                                </div>
                                <!--/Finalizada-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalConfirm
            :modal-id="cancelModalId"
            @onClose="closeModal()"
            @onConfirm="() => handleCancelEvent()"
            >Va a cancelar este evento, esta seguro?
        </ModalConfirm>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';
import { DateTime } from 'luxon';

import { useVfm } from 'vue-final-modal';
import WindowLayout from '@/layouts/Window.layout.vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import ModalConfirm from '@/components/modals/ModalConfirm.vue';

import useSocket from '@/services/useSocket';
import useDoctor from '../Doctores/services/useDoctor';
import { useCita } from './services/useCita';
import { useCitas } from '../Agenda/services/useCitas';

export default {
    name: 'Monitor',
    components: { WindowLayout, Autocomplete, ModalConfirm },
    props: {
        title: { type: String, default: 'Monitor' },
    },
    setup() {
        const store = useStore();
        const tabSelected = ref('programadas');
        const citaClicked = ref();
        const cancelModalId = Symbol('calcelModalId');
        const especialista = ref();
        const { socket } = useSocket();
        const { doctores: especialistas, getDoctores: getEspecialistas } = useDoctor();
        // eslint-disable-next-line object-curly-newline
        const { citas: events, getCitas, buildFilters: citasFilter } = useCitas();
        const { onWaiting, finish, cancelCita } = useCita();

        const vfm = useVfm();

        const handleGetCitas = () => {
            if (especialista.value) {
                const fechaObj = DateTime.now().startOf('day');

                const filters = citasFilter(
                    fechaObj,
                    // eslint-disable-next-line comma-dangle
                    fechaObj.plus({ day: 1 }),
                    // eslint-disable-next-line no-undef
                    undefined,
                    // eslint-disable-next-line comma-dangle
                    especialista.value
                );
                getCitas(filters);
            }
        };

        const especialistaAutocomplete = ref(); // autocomplete input
        const especialistaSelected = ref();
        const onSelectEspecialista = (doctor) => {
            especialista.value = doctor.id;
            especialistaAutocomplete.value.setText(doctor.fullName);
            especialistaSelected.value = doctor;
            handleGetCitas();
        };

        const programed = computed(() => {
            const ar = events.value.filter((e) => e.status === 1);
            ar.sort((a, b) => {
                const aS = DateTime.fromISO(a.timeStart);
                const bS = DateTime.fromISO(b.timeStart);
                return aS.toMillis() - bS.toMillis();
            });

            return ar;
        });
        const wating = computed(() => {
            const ar = events.value.filter((e) => e.status === 2);
            ar.sort((a, b) => {
                const aS = DateTime.fromISO(a.timeStart);
                const bS = DateTime.fromISO(b.timeStart);
                return aS.toMillis() - bS.toMillis();
            });

            return ar;
        });
        const finished = computed(() => {
            const ar = events.value.filter((e) => e.status === 3);
            ar.sort((a, b) => {
                const aS = DateTime.fromISO(a.timeStart);
                const bS = DateTime.fromISO(b.timeStart);
                return aS.toMillis() - bS.toMillis();
            });

            return ar;
        });

        onBeforeMount(() => {
            handleGetCitas();
            socket.on('rte:CareAgendaCitas', async () => {
                handleGetCitas();
            });
        });

        const normarlizeDate = (date) => DateTime.fromISO(date).toFormat('hh:mm a');

        const procedimiento = (event) => {
            if (event.type === 'procedimiento') {
                return event.procedimiento.name;
            }
            return 'Consulta';
        };

        const handleCancelEventClick = (cita) => {
            citaClicked.value = cita;
            vfm.open(cancelModalId);
        };

        const handleCancelEvent = async () => {
            vfm.close(cancelModalId);
            await cancelCita(citaClicked.value.id);
            citaClicked.value = null;
            // handleGetCitas();
        };

        const handleonWaitingClick = async (cita) => {
            await onWaiting(cita.id);
            // handleGetCitas();
        };

        const handleFinishClick = async (cita) => {
            await finish(cita.id);
            // handleGetCitas();
        };

        const handleNewConsultaClick = (cita) => {
            store.dispatch('ContentManager/openWindow', {
                component: 'ConsultaOftalmologiaForm',
                id: `ConsultaOftalmologiaForm_${cita.paciente.id}`,
                name: 'Consulta',
                params: {
                    name: 'Consulta',
                    headerTitle: 'Editar Consulta',
                    item: { paciente: cita.paciente },
                },
            });
        };

        const handleOpenPacienteClick = (cita) => {
            store.dispatch('ContentManager/openWindow', {
                component: 'PacienteForm',
                id: `PacienteForm_${cita.paciente.id}`,
                name: 'Paciente',
                params: {
                    name: 'Paciente',
                    headerTitle: 'Editar Paciente',
                    item: cita.paciente,
                },
            });
        };

        const closeModal = () => {
            vfm.close(cancelModalId);
        };

        return {
            handleGetCitas,
            programed,
            wating,
            finished,
            procedimiento,
            normarlizeDate,
            especialista,
            especialistaAutocomplete,
            especialistaSelected,
            onSelectEspecialista,
            especialistas,
            getEspecialistas,
            handleCancelEventClick,
            handleonWaitingClick,
            handleCancelEvent,
            handleFinishClick,
            handleOpenPacienteClick,
            handleNewConsultaClick,
            cancelModalId,
            citaClicked,
            closeModal,
            tabSelected,
        };
    },
};
</script>

<style scoped></style>
